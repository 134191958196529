import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { APP_ENV } from '~/env';

import { ToastType } from '../../../../../core/presentation/common/types/toast-types';
import { useAuth } from '../../../../../core/presentation/hooks/use-auth';
import { useToast } from '../../../../../core/presentation/hooks/use-toast';
import FormProvider from '../../../../../core/presentation/providers/form-provider';
import BaseAuthLayout from '../../../components/base-auth-layout/base-auth-layout';
import { UserCompanyResponseModel } from '../../domain/models/companies/company-response-model';
import { LoginForm } from '../../domain/models/login-models';
import { PaginationQueryParamsModel } from '../../domain/models/pagination-query-params-models';
import { RegisterRoutes } from '../../routes/routes';
import {
  useEmailOrUsernameLoginMutation,
  useGetVehiclesMutation,
  useMeMutation
} from '../../store/hooks';
import { useRegisterUserDataStore } from '../../store/slice/register-user-data-slice';
import FormLogin from './form-login/form-login';

export const redirectToV1 = ({
  to,
  accessToken,
  refreshToken
}: {
  to: string;
  accessToken: string;
  refreshToken: string;
}) => {
  const searchParams = new URLSearchParams();
  searchParams.append('accessToken', accessToken);
  searchParams.append('refreshToken', refreshToken);
  window.location.replace(`${to}?${searchParams.toString()}`);
};

interface CustomSignInFunction<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (values: T): any;
}

export const useLoginLogic = <T,>(
  customSignInFunction?: CustomSignInFunction<T>,
  isLoading?: boolean
) => {
  const navigate = useNavigate();
  const { useLogin, logout, getLogin } = useAuth();
  const { addToast } = useToast();
  const { setRegisterState } = useRegisterUserDataStore();
  const [loadingLogic, setLoadingLogic] = useState(false);
  const [me] = useMeMutation();
  const [getVehicles] = useGetVehiclesMutation();

  const [apiError, setApiError] = useState<string | null>(null);

  const getVehiclesRequest = async (
    values: PaginationQueryParamsModel & { companyId?: string },
    tokens: { accessToken: string; refreshToken: string },
    company: UserCompanyResponseModel
  ) => {
    try {
      const response = await getVehicles(values).unwrap();

      const hasMoreThan5Vehicles = response?.length > 5;
      const hasNoSubscriptions =
        !company.subscriptions || company.subscriptions.length === 0;

      setRegisterState({
        vehiclesCount: response?.length ?? 0,
        companies: [{ _id: values.companyId, ...company }]
      });

      if (hasMoreThan5Vehicles && hasNoSubscriptions) {
        return navigate(RegisterRoutes.SELECT_PLAN);
      }

      if (!response?.length || hasNoSubscriptions) {
        return navigate(RegisterRoutes.VEHICLES_DATA);
      }

      redirectToV1({
        accessToken: tokens.accessToken,
        refreshToken: tokens.refreshToken,
        to: APP_ENV.FRONTEND_V1_REDIRECT_URL
      });
    } catch (error) {
      addToast({
        type: ToastType.ERROR,
        text: error.message
      });
    }
  };

  const meRequest = async (tokens: {
    accessToken: string;
    refreshToken: string;
  }) => {
    try {
      const response = await me({})
        .unwrap()
        .catch(() => logout());

      if (!response) return;

      setRegisterState({
        user: {
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          active: true
        },
        isAuthenticated: true
      });

      if (response?.origin === 'v1') {
        return redirectToV1({
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          to: APP_ENV.FRONTEND_V1_REDIRECT_URL
        });
      }

      if (response?.companies?.length === 0) {
        return navigate(RegisterRoutes.COMPANY_DATA);
      }

      getVehiclesRequest(
        {
          pageSize: '10',
          offset: '1',
          companyId: response.companies[0].id
        },
        tokens,
        response.companies[0]
      );
    } catch (error) {
      addToast({
        type: ToastType.ERROR,
        text: error.message
      });
    }
  };

  const signIn = async (values: T) => {
    try {
      setLoadingLogic(true);
      const response = await customSignInFunction(values).unwrap();

      useLogin(response);

      const user = {
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.email,
        active: response.active
      };

      setRegisterState({ user, carQuantity: response.carQuantity , isAuthenticated : true });

      if (response.firstName === '' || response.lastName === '') {
        return navigate(RegisterRoutes.PERSONAL_DATA);
      }

      if (response.requestChangePassword) {
        return navigate('/reset-password');
      } else {
        meRequest({
          accessToken: response.accessToken,
          refreshToken: response.refreshToken
        });
      }
    } catch (error) {
      setLoadingLogic(false);
      setApiError(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const logoutStatus = searchParams.get('logout');

    if (logoutStatus === 'true') {
      logout();
    }
  }, [location, history]);

  useEffect(() => {
    const fetchData = async () => {
      const tokens = getLogin();

      if (!tokens) return;

      const response = await me({}).unwrap();

      if (!response.requestChangePassword) {
        return redirectToV1({
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          to: APP_ENV.FRONTEND_V1_REDIRECT_URL
        });
      }
    };

    fetchData();
  }, []);

  return {
    signIn,
    meRequest,
    apiError,
    isLoading: isLoading || loadingLogic
  };
};

const Login = () => {
  const [login, { isLoading }] = useEmailOrUsernameLoginMutation();
  const { useLogin } = useAuth();
  const { meRequest } = useLoginLogic();
  const { clearStorage } = useRegisterUserDataStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('redirectToken');

  const {
    signIn,
    apiError,
    isLoading: loginLoading
  } = useLoginLogic<LoginForm>(login, isLoading);

  useEffect(() => {
    useLogin({
      accessToken: token,
      refreshToken: undefined
    });

    if (!token) return;

    meRequest({
      accessToken: token,
      refreshToken: undefined
    });
  }, [token]);

  useEffect(() => {
    clearStorage();
  }, []);

  return (
    <BaseAuthLayout
      leftChildren={
        <FormProvider mode='onSubmit'>
          <FormLogin
            onSubmit={signIn}
            apiError={apiError}
            {...{ isLoading: loginLoading || isLoading }}
          />
        </FormProvider>
      }
      rightChildren={<img src='/assets/images/login.svg' />}
    />
  );
};

export default Login;
