import { useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import BaseButton from '~/app/core/presentation/components/base-button/base-button';
import Logo from '~/app/core/presentation/components/logo/logo';
import { useAuth } from '~/app/core/presentation/hooks/use-auth';

import { redirectToV1 } from '../../auth/presentation/login/login';
import {
  ContentBox,
  Title,
  SuccessBox,
  HorizontalContainer,
  BackAndLogoBox,
  LogoBox
} from './success-setting-styles';

export type SettingPlanProps = {
  title: string;
  description: string;
  redirectLink?: string;
  redirectToAfterTimeout?: number;
  buttonLabel?: string;
  buttonLink?: (() => void) | string;
  textInfo?: string;
  status: 'success' | 'error' | 'loading';
  disableLogo?: boolean;
};

const SuccessSetting = ({
  title,
  description,
  buttonLabel,
  redirectLink,
  redirectToAfterTimeout,
  buttonLink,
  textInfo,
  status,
  disableLogo
}: SettingPlanProps) => {
  const { getLogin } = useAuth();
  const tokens = getLogin();

  useEffect(() => {
    if (redirectLink && redirectToAfterTimeout)
      setTimeout(() => {
        redirectToV1({
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          to: redirectLink
        });
      }, redirectToAfterTimeout * 1000);
  }, []);

  const statusMapper = {
    success: <CheckIcon sx={{ fontSize: 100, color: '#188F00' }} />,
    error: <InfoIcon sx={{ fontSize: 100, color: '#C61010' }} />,
    loading: <CircularProgress sx={{ fontSize: 100, color: '#188F00' }} />
  };

  return (
    <SuccessBox>
      {!disableLogo && (
        <BackAndLogoBox>
          <LogoBox>
            <Logo />
          </LogoBox>
        </BackAndLogoBox>
      )}
      <ContentBox>
        {statusMapper[status]}
        <HorizontalContainer>
          <Title color={status === 'error' ? '#C61010' : '#188F00'}>
            {title}
          </Title>
        </HorizontalContainer>
        {description && (
          <HorizontalContainer>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </HorizontalContainer>
        )}
        {textInfo && (
          <HorizontalContainer>
            <p dangerouslySetInnerHTML={{ __html: textInfo }} />
          </HorizontalContainer>
        )}
        {buttonLabel && (
          <BaseButton
            onClick={
              typeof buttonLink === 'function'
                ? buttonLink
                : () => window.open(buttonLink, '_blank')
            }
            sx={{ maxWidth: 339 }}
            color='primary'
            variant='contained'
          >
            {buttonLabel}
          </BaseButton>
        )}
      </ContentBox>
    </SuccessBox>
  );
};

export default SuccessSetting;
