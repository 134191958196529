import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const cardsBrandsList = ['visa', 'mastercard', 'american_express'];

const schema = z.object({
  cardBrand: z.string().refine((val) => cardsBrandsList.includes(val)),
  cardNumber: z.string().min(19).max(19),
  holderName: z.string().min(2).max(19),
  expirationDate: z.string().min(5).max(5),
  price: z.string().min(1),
  cvv: z.string().min(3).max(3),
  carQuantity: z.string().min(1)
});

export const PaymentPlanValidator = zodResolver(schema);
