import { authApi } from './api';

export const {
  useEmailOrUsernameLoginMutation,
  usePasswordMutation,
  useChangePasswordMutation,
  useConsumablesMutation,
  useGoogleLoginMutation,
  useLoginByLinkMutation,
  useAuthorizationUrlMutation,
  useAccessCodeMutation,
  useValidateAccessCodeMutation,
  useSignUpMutation,
  useValidateCodeMutation,
  useResendCodeMutation,
  useCnpjLookupMutation,
  useCnpjValidateMutation,
  useCreateCompanyMutation,
  useAddVehiclesMutation,
  useContactSalesMutation,
  useGetPlansMutation,
  useCreateSubscriptionMutation,
  useUpdateMeMutation,
  useMeMutation,
  useGetVehiclesMutation,
  useSendEmailLinkFlowMutation,
  useGetMeQuery,
  useUpdateSubscriptionMutation,
  useGetSubscriptionByIdMutation,
  useGetSubscriptionByCompanyIdMutation
} = authApi;
