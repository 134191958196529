import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';

import { Price } from '../../auth/domain/models/plans/plan-response-model';
import { PlanLabelContainer, PlanTitle } from './radio-plan-label-styles';

const RadioPlanLabel = ({ price }: { price: Price }) => {
  const { t } = useTranslation(['payment-form']);
  const [total, setTotal] = useState('0,00');
  const [monthlyTotal, setMonthlyTotal] = useState('0,00');
  const [yearlyTotal, setYearlyTotal] = useState('0,00');

  const { registerState } = useRegisterUserDataStore();

  useEffect(() => {
    const totalAmount = Number(price?.amount * registerState?.vehiclesCount);
    setTotal(totalAmount.toFixed(2).replace('.', ','));
    const monthlyAmount = Number(totalAmount / 12);
    setMonthlyTotal(monthlyAmount.toFixed(2).replace('.', ','));
    setYearlyTotal(totalAmount.toFixed(2).replace('.', ','));
  }, [registerState, total]);

  const isYearly = price?.frequency === 'yearly';

  return (
    <PlanLabelContainer>
      <PlanTitle>
        {t(isYearly ? 'chargedAnnually' : 'chargedMonthly')}
      </PlanTitle>
      {isYearly && (
        <div>
          {t('priceAnnually', {
            price: yearlyTotal
          })}
        </div>
      )}
      {isYearly && <div>({t('priceMonthly', { price: monthlyTotal })})</div>}
      {!isYearly && <div>{t('priceMonthly', { price: total })}</div>}
    </PlanLabelContainer>
  );
};

export default RadioPlanLabel;
