type MethodProps = {
  currentPlan: boolean;
  disableColumn: boolean;
  onclick: () => void;
};

type PlansButtonMethods = {
  freePlan: MethodProps;

  proPlan: MethodProps;

  enterprisePlan: MethodProps;
};

export const createPlans = ({
  freePlan,
  proPlan,
  enterprisePlan
}: PlansButtonMethods): PlanDetails[] => {
  return [
    {
      name: 'Starter',
      color: '#63AB36',
      lineColor: '#63AB36',
      paymentLink: 'https://www.google.com',
      buttonAction: freePlan.onclick,
      currentPlan: freePlan.currentPlan,
      disableColumn: freePlan.disableColumn,
      functionalities: [
        {
          name: 'Quantidade de veículos',
          active: true,
          helperText:
            'Refere-se ao total de veículos que você pode incluir e gerenciar dentro da plataforma. Ao explorar planos avançados, você pode desfrutar de limites ampliados.',
          description: '10'
        },
        {
          name: 'CNPJs por assinatura',
          active: true,
          helperText:
            'No plano freemium você pode cadastrar apenas um CNPJ. Se precisar gerenciar mais CNPJs essa opção é acessível nos planos pagos. Cada conta suporta inicialmente um CNPJ, garantindo uma gestão intuitiva. Precisa associar mais empresas? Explore nossos planos pagos para ampliar suas possibilidades sem perder a simplicidade',
          description: '1'
        },
        {
          name: 'Edição de Placas',
          active: true,
          helperText:
            'No plano gratuito você pode incluir até 10 placas e mudar uma placa a cada 6 meses. Se precisar editar placas com mais frequência ou incluir mais do que 10 placas, escolha o plano ideal para você.',
          description: 'A cada 6 meses'
        },
        {
          name: 'Suporte',
          active: true,
          helperText:
            'Nossa plataforma fornece recursos educativos para todos. Aqueles em planos pagos têm a vantagem do suporte humano, e as contas enterprise se beneficiam com um Gerente de Conta exclusivo.',
          description: 'E-mail'
        },
        {
          name: 'Armazenamento e Gestão de vencimento do CRLV',
          active: true,
          helperText:
            'Sua tranquilidade ao volante começa com a documentação em dia. Nossa ferramenta simplifica o acompanhamento do licenciamento do seu veículo, alertando sobre as datas de vencimento e oferecendo o acesso fácil ao documento digital a qualquer momento.',
          description: '2'
        },
        {
          name: 'Usuários por conta',
          active: true,
          helperText:
            'A quantidade de usuários que pode adicionar na conta. O plano gratuito permite até 2 usuários. Os planos pagos permitem a adição ilimitada de usuários.',
          description: '2'
        },
        {
          name: 'Extração de todos os dados para análise',
          active: true,
          helperText:
            'Com apenas alguns cliques, você terá todas as informações necessárias organizadas e acessíveis através de uma planilha excel, facilitando a análise e compartilhamento. Nossa interface amigável guia você durante todo o processo, tornando a extração de dados uma tarefa simples e eficaz.'
        },
        {
          name: 'Relatórios e Dashboards',
          active: true,
          helperText:
            'Descubra uma visão clara e precisa com relatórios e dashboards padronizados, entregando insights valiosos que são essenciais para o gerenciamento eficaz da frota. Com uma interface amigável e descrições claras, explorar os dados e descobrir informações que impulsionam a excelência operacional nunca foi tão fácil.'
        },
        {
          name: 'Integração e Conexão via Webhook e APIs',
          active: false,
          helperText:
            'Explore a simplicidade de conectar serviços com nossa funcionalidade que suporta integração via Webhook e APIs em soluções SaaS, oferecendo troca de dados eficaz, notificações em tempo real e automação de processos.'
        }
      ],
      module: [
        {
          name: 'Multas',
          functionalities: [
            {
              name: 'Emissão de boleto de multas',
              active: true,
              helperText:
                'Aqui você consegue gerar o boleto da sua multa com facilidade. Pague antes do vencimento e aproveite possíveis descontos. Evite problemas futuros, mantendo-se em dia.'
            },
            {
              name: 'Pagamento de Multas',
              active: true,
              description: 'Valor da multa + 5% de taxa',
              helperText:
                'Visualize as infrações e pague as multas com facilidade através do sistema com poucos cliques e sem necessidade de emissão do boleto ou envio a bancos ou despachantes.'
            },
            {
              name: 'Integração com SNE',
              active: true,
              helperText:
                'A integração com o SNE permite que tenha visibilidade de 100% das multas de sua frota e facilita o pagamento com até 40% de desconto das multas. Aumente a segurança da sua frota. Conecte-se de forma simples e eficaz com a basenacional e receba informações cruciais para proteger seus veículos'
            },
            {
              name: 'Captura de multas e notificações em todos os estados Brasileiros',
              active: false,
              helperText:
                'Receba alertas e gerencie infrações de trânsito de todos os estados brasileiros em um só lugar. Evite surpresas, reduza custos e promova uma direção mais segura com nossa solução integrada.'
            },
            {
              name: 'Monitoramento de multa de embarcador via CNPJ',
              active: false,
              helperText:
                'Seja notificado imediatamente sobre multas ligadas ao CNPJ da sua empresa. Com nossa plataforma, conteste, pague ou redirecione infrações facilmente. Mantenha-se em conformidade com órgãos como DNIT, DPRF, ANTT, DER-SP e outros, enquanto gerencia suas finanças de forma eficaz.'
            },
            {
              name: 'Gerenciamento de Multas',
              active: false,
              helperText:
                'Evite o estresse das multas de trânsito! Com nossa solução, você recebe alertas em tempo real de novas infrações, tem opções para contestar ou pagar online e ainda pode identificar padrões para promover uma direção mais segura.'
            },
            {
              name: 'Captura de ANTTs, Cronotacógrafo e Licenças',
              active: false,
              helperText:
                'Centralize e monitore todas as certificações e permissões da sua frota. Com nossa ferramenta, você tem uma visão clara do status do seu registro ANTT, inspeções do cronotacógrafo e licenças essenciais, tudo em um só lugar, além de controlar vencimentos, renovações, históricos e anexar documentos.'
            }
          ]
        },
        {
          name: 'Condutores',
          functionalities: [
            {
              name: 'Gestão de Vencimento de CNH',
              active: true,
              helperText:
                'Mantenha-se sempre em conformidade e evite surpresas. Centralize e monitore facilmente as datas de validade das CNHs de todos os seus motoristas, e receba notificações proativas para garantir renovações pontuais.'
            },
            {
              name: 'Gestão de Vencimento de Cursos',
              active: true,
              helperText:
                'Evite surpresas e mantenha suas operações seguras e fluidas. Com nossa ferramenta, você monitora facilmente as datas de renovação dos cursos de seus motoristas, garantindo que todos estejam devidamente treinados e certificados.'
            },
            {
              name: 'Download de Formulário para Indicação de Condutores',
              active: true,
              helperText:
                'Recebeu uma multa? Identifique rapidamente o motorista responsável. Baixe o formulário e envie ao órgão de trânsito em poucos cliques, evitando penalidades adicionais e promovendo a responsabilidade de um trânsito mais seguro.'
            },
            {
              name: 'Gerenciamento de Condutores',
              active: false,
              helperText:
                'Mantenha seus motoristas no caminho certo! Acompanhe o desempenho, treinamentos e infrações de cada condutor, garantindo uma direção mais segura e eficiente para toda a sua frota.'
            },
            {
              name: 'Monitoramento de Situação da CNH',
              active: true,
              helperText:
                'Mantenha sua frota em movimento com segurança e conformidade. Nosso sistema monitora continuamente o status das CNHs, notificando sobre vencimentos e bloqueios em todos os estados. Para pontuação, as informações podem variar conforme a UF.',
              description: 'R$ 9,90'
            },
            {
              name: 'Gestão e Consulta de Vencimento de Laudo Toxicológico',
              active: true,
              helperText:
                'Mantenha sua frota em movimento com segurança e conformidade. Nosso sistema monitora continuamente o status das CNHs, notificando sobre vencimentos e bloqueios em todos os estados. Para pontuação, as informações podem variar conforme a UF.'
            }
          ]
        },
        {
          name: 'Viagens',
          functionalities: [
            {
              name: 'Gestão de Histórico de Viagens',
              active: false,
              helperText:
                'Mantenha o controle sobre as movimentações da sua frota. Registre e analise informações de todas as viagens, permitindo decisões mais informadas e melhor desempenho nas estradas.'
            },
            {
              name: 'Gestão de Conjuntos e Histórico de Alterações (tração e reboques)',
              active: false,
              helperText:
                'Acompanhe cada combinação na estrada. Registre e visualize facilmente quais caminhões estão associados a quais reboques, e fique por dentro de todas as alterações. Garanta operações seguras, otimizadas e em conformidade com cada movimento.'
            },
            {
              name: 'Controle de Quilometragem',
              active: false,
              helperText:
                'Acompanhe cada quilômetro percorrido com precisão. Nossa ferramenta ajuda você a monitorar a distância percorrida por cada veículo, otimizando manutenções, gerenciando custos e garantindo rotas eficientes.'
            }
          ]
        }
      ]
    },
    {
      name: 'Pro',
      color: '#457826',
      lineColor: '#457826',
      paymentLink: 'https://www.google.com',
      buttonAction: proPlan.onclick,
      currentPlan: proPlan.currentPlan,
      disableColumn: proPlan.disableColumn,
      functionalities: [
        {
          name: 'Quantidade de veículos',
          active: true,
          helperText:
            'Refere-se ao total de veículos que você pode incluir e gerenciar dentro da plataforma. Ao explorar planos avançados, você pode desfrutar de limites ampliados.',
          description: '100'
        },
        {
          name: 'CNPJs por assinatura',
          active: true,
          helperText:
            'No plano freemium você pode cadastrar apenas um CNPJ. Se precisar gerenciar mais CNPJs essa opção é acessível nos planos pagos. Cada conta suporta inicialmente um CNPJ, garantindo uma gestão intuitiva. Precisa associar mais empresas? Explore nossos planos pagos para ampliar suas possibilidades sem perder a simplicidade',
          description: 'Ilimitado'
        },
        {
          name: 'Edição de Placas',
          active: true,
          helperText:
            'No plano gratuito você pode incluir até 10 placas e mudar uma placa a cada 6 meses. Se precisar editar placas com mais frequência ou incluir mais do que 10 placas, escolha o plano ideal para você.',
          description: 'Ilimitado'
        },
        {
          name: 'Suporte',
          active: true,
          helperText:
            'Nossa plataforma fornece recursos educativos para todos. Aqueles em planos pagos têm a vantagem do suporte humano, e as contas enterprise se beneficiam com um Gerente de Conta exclusivo.',
          description: 'E-mail e Whatsapp'
        },
        {
          name: 'Armazenamento e Gestão de vencimento do CRLV',
          active: true,
          helperText:
            'Sua tranquilidade ao volante começa com a documentação em dia. Nossa ferramenta simplifica o acompanhamento do licenciamento do seu veículo, alertando sobre as datas de vencimento e oferecendo o acesso fácil ao documento digital a qualquer momento.',
          description: 'Ilimitado'
        },

        {
          name: 'Usuários por conta',
          active: true,
          helperText:
            'A quantidade de usuários que pode adicionar na conta. O plano gratuito permite até 2 usuários. Os planos pagos permitem a adição ilimitada de usuários.',
          description: 'Ilimitado'
        },
        {
          name: 'Extração de todos os dados para análise',
          active: true,
          helperText:
            'Com apenas alguns cliques, você terá todas as informações necessárias organizadas e acessíveis através de uma planilha excel, facilitando a análise e compartilhamento. Nossa interface amigável guia você durante todo o processo, tornando a extração de dados uma tarefa simples e eficaz.'
        },
        {
          name: 'Relatórios e Dashboards',
          active: true,
          helperText:
            'Descubra uma visão clara e precisa com relatórios e dashboards padronizados, entregando insights valiosos que são essenciais para o gerenciamento eficaz da frota. Com uma interface amigável e descrições claras, explorar os dados e descobrir informações que impulsionam a excelência operacional nunca foi tão fácil.'
        },
        {
          name: 'Integração e Conexão via Webhook e APIs',
          active: false,
          helperText:
            'Explore a simplicidade de conectar serviços com nossa funcionalidade que suporta integração via Webhook e APIs em soluções SaaS, oferecendo troca de dados eficaz, notificações em tempo real e automação de processos.'
        }
      ],
      module: [
        {
          name: 'Multas',
          functionalities: [
            {
              name: 'Emissão de boleto de multas',
              active: true,
              helperText:
                'Aqui você consegue gerar o boleto da sua multa com facilidade. Pague antes do vencimento e aproveite possíveis descontos. Evite problemas futuros, mantendo-se em dia.'
            },
            {
              name: 'Pagamento de Multas',
              active: true,
              description: 'Valor da multa + 3% de taxa',
              helperText:
                'Visualize as infrações e pague as multas com facilidade através do sistema com poucos cliques e sem necessidade de emissão do boleto ou envio a bancos ou despachantes.'
            },
            {
              name: 'Integração com SNE',
              active: true,
              helperText:
                'A integração com o SNE permite que tenha visibilidade de 100% das multas de sua frota e facilita o pagamento com até 40% de desconto das multas. Aumente a segurança da sua frota. Conecte-se de forma simples e eficaz com a basenacional e receba informações cruciais para proteger seus veículos'
            },
            {
              name: 'Captura de multas e notificações em todos os estados Brasileiros',
              active: false,
              helperText:
                'Receba alertas e gerencie infrações de trânsito de todos os estados brasileiros em um só lugar. Evite surpresas, reduza custos e promova uma direção mais segura com nossa solução integrada.'
            },
            {
              name: 'Monitoramento de multa de embarcador via CNPJ',
              active: false,
              helperText:
                'Seja notificado imediatamente sobre multas ligadas ao CNPJ da sua empresa. Com nossa plataforma, conteste, pague ou redirecione infrações facilmente. Mantenha-se em conformidade com órgãos como DNIT, DPRF, ANTT, DER-SP e outros, enquanto gerencia suas finanças de forma eficaz.'
            },
            {
              name: 'Gerenciamento de Multas',
              active: false,
              helperText:
                'Evite o estresse das multas de trânsito! Com nossa solução, você recebe alertas em tempo real de novas infrações, tem opções para contestar ou pagar online e ainda pode identificar padrões para promover uma direção mais segura.'
            },
            {
              name: 'Captura de ANTTs, Cronotacógrafo e Licenças',
              active: false,
              helperText:
                'Centralize e monitore todas as certificações e permissões da sua frota. Com nossa ferramenta, você tem uma visão clara do status do seu registro ANTT, inspeções do cronotacógrafo e licenças essenciais, tudo em um só lugar, além de controlar vencimentos, renovações, históricos e anexar documentos.'
            }
          ]
        },
        {
          name: 'Condutores',
          functionalities: [
            {
              name: 'Gestão de Vencimento de CNH',
              active: true,
              helperText:
                'Mantenha-se sempre em conformidade e evite surpresas. Centralize e monitore facilmente as datas de validade das CNHs de todos os seus motoristas, e receba notificações proativas para garantir renovações pontuais.'
            },
            {
              name: 'Gestão de Vencimento de Cursos',
              active: true,
              helperText:
                'Evite surpresas e mantenha suas operações seguras e fluidas. Com nossa ferramenta, você monitora facilmente as datas de renovação dos cursos de seus motoristas, garantindo que todos estejam devidamente treinados e certificados.'
            },
            {
              name: 'Download de Formulário para Indicação de Condutores',
              active: true,
              helperText:
                'Recebeu uma multa? Identifique rapidamente o motorista responsável. Baixe o formulário e envie ao órgão de trânsito em poucos cliques, evitando penalidades adicionais e promovendo a responsabilidade de um trânsito mais seguro.'
            },
            {
              name: 'Gerenciamento de Condutores',
              active: true,
              helperText:
                'Mantenha seus motoristas no caminho certo! Acompanhe o desempenho, treinamentos e infrações de cada condutor, garantindo uma direção mais segura e eficiente para toda a sua frota.'
            },
            {
              name: 'Monitoramento de Situação da CNH',
              active: true,
              helperText:
                'Mantenha sua frota em movimento com segurança e conformidade. Nosso sistema monitora continuamente o status das CNHs, notificando sobre vencimentos e bloqueios em todos os estados. Para pontuação, as informações podem variar conforme a UF.',
              description: 'R$ 3,99'
            }
          ]
        },
        {
          name: 'Viagens',
          functionalities: [
            {
              name: 'Gestão de Histórico de Viagens',
              active: true,
              helperText:
                'Mantenha o controle sobre as movimentações da sua frota. Registre e analise informações de todas as viagens, permitindo decisões mais informadas e melhor desempenho nas estradas.'
            },
            {
              name: 'Gestão de Conjuntos e Histórico de Alterações (tração e reboques)',
              active: true,
              helperText:
                'Acompanhe cada combinação na estrada. Registre e visualize facilmente quais caminhões estão associados a quais reboques, e fique por dentro de todas as alterações. Garanta operações seguras, otimizadas e em conformidade com cada movimento.'
            },
            {
              name: 'Controle de Quilometragem',
              active: true,
              helperText:
                'Acompanhe cada quilômetro percorrido com precisão. Nossa ferramenta ajuda você a monitorar a distância percorrida por cada veículo, otimizando manutenções, gerenciando custos e garantindo rotas eficientes.'
            }
          ]
        }
      ]
    },
    {
      name: 'Enterprise',
      color: '#284516',
      lineColor: '#284516',
      paymentLink: 'https://www.google.com',
      buttonAction: enterprisePlan.onclick,
      currentPlan: enterprisePlan.currentPlan,
      disableColumn: enterprisePlan.disableColumn,
      functionalities: [
        {
          name: 'Quantidade de veículos',
          active: true,
          helperText:
            'Refere-se ao total de veículos que você pode incluir e gerenciar dentro da plataforma. Ao explorar planos avançados, você pode desfrutar de limites ampliados.',
          description: 'Ilimitado'
        },
        {
          name: 'CNPJs por assinatura',
          active: true,
          helperText:
            'No plano freemium você pode cadastrar apenas um CNPJ. Se precisar gerenciar mais CNPJs essa opção é acessível nos planos pagos. Cada conta suporta inicialmente um CNPJ, garantindo uma gestão intuitiva. Precisa associar mais empresas? Explore nossos planos pagos para ampliar suas possibilidades sem perder a simplicidade',
          description: 'Ilimitado'
        },
        {
          name: 'Edição de Placas',
          active: true,
          helperText:
            'No plano gratuito você pode incluir até 10 placas e mudar uma placa a cada 6 meses. Se precisar editar placas com mais frequência ou incluir mais do que 10 placas, escolha o plano ideal para você.',
          description: 'Ilimitado'
        },
        {
          name: 'Suporte',
          active: true,
          helperText:
            'Nossa plataforma fornece recursos educativos para todos. Aqueles em planos pagos têm a vantagem do suporte humano, e as contas enterprise se beneficiam com um Gerente de Conta exclusivo.',
          description: 'Personalizado'
        },
        {
          name: 'Armazenamento e Gestão de vencimento do CRLV',
          active: true,
          helperText:
            'Sua tranquilidade ao volante começa com a documentação em dia. Nossa ferramenta simplifica o acompanhamento do licenciamento do seu veículo, alertando sobre as datas de vencimento e oferecendo o acesso fácil ao documento digital a qualquer momento.',
          description: 'Ilimitado'
        },
        {
          name: 'Usuários por conta',
          active: true,
          helperText:
            'A quantidade de usuários que pode adicionar na conta. O plano gratuito permite até 2 usuários. Os planos pagos permitem a adição ilimitada de usuários.',
          description: 'Ilimitado'
        },
        {
          name: 'Extração de todos os dados para análise',
          active: true,
          helperText:
            'Com apenas alguns cliques, você terá todas as informações necessárias organizadas e acessíveis através de uma planilha excel, facilitando a análise e compartilhamento. Nossa interface amigável guia você durante todo o processo, tornando a extração de dados uma tarefa simples e eficaz.'
        },
        {
          name: 'Relatórios e Dashboards',
          active: true,
          helperText:
            'Descubra uma visão clara e precisa com relatórios e dashboards padronizados, entregando insights valiosos que são essenciais para o gerenciamento eficaz da frota. Com uma interface amigável e descrições claras, explorar os dados e descobrir informações que impulsionam a excelência operacional nunca foi tão fácil.'
        },
        {
          name: 'Integração e Conexão via Webhook e APIs',
          active: true,
          helperText:
            'Explore a simplicidade de conectar serviços com nossa funcionalidade que suporta integração via Webhook e APIs em soluções SaaS, oferecendo troca de dados eficaz, notificações em tempo real e automação de processos.'
        }
      ],
      module: [
        {
          name: 'Multas',
          functionalities: [
            {
              name: 'Emissão de boleto de multas',
              active: true,
              helperText:
                'Aqui você consegue gerar o boleto da sua multa com facilidade. Pague antes do vencimento e aproveite possíveis descontos. Evite problemas futuros, mantendo-se em dia.'
            },
            {
              name: 'Pagamento de Multas',
              active: true,
              description: 'Personalizado',
              helperText:
                'Visualize as infrações e pague as multas com facilidade através do sistema com poucos cliques e sem necessidade de emissão do boleto ou envio a bancos ou despachantes.'
            },
            {
              name: 'Integração com SNE',
              active: true,
              helperText:
                'A integração com o SNE permite que tenha visibilidade de 100% das multas de sua frota e facilita o pagamento com até 40% de desconto das multas. Aumente a segurança da sua frota. Conecte-se de forma simples e eficaz com a basenacional e receba informações cruciais para proteger seus veículos'
            },
            {
              name: 'Captura de multas e notificações em todos os estados Brasileiros',
              active: true,
              helperText:
                'Receba alertas e gerencie infrações de trânsito de todos os estados brasileiros em um só lugar. Evite surpresas, reduza custos e promova uma direção mais segura com nossa solução integrada.'
            },
            {
              name: 'Monitoramento de multa de embarcador via CNPJ',
              active: true,
              helperText:
                'Seja notificado imediatamente sobre multas ligadas ao CNPJ da sua empresa. Com nossa plataforma, conteste, pague ou redirecione infrações facilmente. Mantenha-se em conformidade com órgãos como DNIT, DPRF, ANTT, DER-SP e outros, enquanto gerencia suas finanças de forma eficaz.'
            },
            {
              name: 'Gerenciamento de Multas',
              active: true,
              helperText:
                'Evite o estresse das multas de trânsito! Com nossa solução, você recebe alertas em tempo real de novas infrações, tem opções para contestar ou pagar online e ainda pode identificar padrões para promover uma direção mais segura.'
            },
            {
              name: 'Captura de ANTTs, Cronotacógrafo e Licenças',
              active: true,
              helperText:
                'Centralize e monitore todas as certificações e permissões da sua frota. Com nossa ferramenta, você tem uma visão clara do status do seu registro ANTT, inspeções do cronotacógrafo e licenças essenciais, tudo em um só lugar, além de controlar vencimentos, renovações, históricos e anexar documentos.'
            }
          ]
        },
        {
          name: 'Condutores',
          functionalities: [
            {
              name: 'Gestão de Vencimento de CNH',
              active: true,
              helperText:
                'Mantenha-se sempre em conformidade e evite surpresas. Centralize e monitore facilmente as datas de validade das CNHs de todos os seus motoristas, e receba notificações proativas para garantir renovações pontuais.'
            },
            {
              name: 'Gestão de Vencimento de Cursos',
              active: true,
              helperText:
                'Evite surpresas e mantenha suas operações seguras e fluidas. Com nossa ferramenta, você monitora facilmente as datas de renovação dos cursos de seus motoristas, garantindo que todos estejam devidamente treinados e certificados.'
            },
            {
              name: 'Download de Formulário para Indicação de Condutores',
              active: true,
              helperText:
                'Recebeu uma multa? Identifique rapidamente o motorista responsável. Baixe o formulário e envie ao órgão de trânsito em poucos cliques, evitando penalidades adicionais e promovendo a responsabilidade de um trânsito mais seguro.'
            },
            {
              name: 'Gerenciamento de Condutores',
              active: true,
              helperText:
                'Mantenha seus motoristas no caminho certo! Acompanhe o desempenho, treinamentos e infrações de cada condutor, garantindo uma direção mais segura e eficiente para toda a sua frota.'
            },
            {
              name: 'Monitoramento de Situação da CNH',
              active: true,
              helperText:
                'Mantenha sua frota em movimento com segurança e conformidade. Nosso sistema monitora continuamente o status das CNHs, notificando sobre vencimentos e bloqueios em todos os estados. Para pontuação, as informações podem variar conforme a UF.',
              description: 'Sob Consulta'
            }
          ]
        },
        {
          name: 'Viagens',
          functionalities: [
            {
              name: 'Gestão de Histórico de Viagens',
              active: true,
              helperText:
                'Mantenha o controle sobre as movimentações da sua frota. Registre e analise informações de todas as viagens, permitindo decisões mais informadas e melhor desempenho nas estradas.'
            },
            {
              name: 'Gestão de Conjuntos e Histórico de Alterações (tração e reboques)',
              active: true,
              helperText:
                'Acompanhe cada combinação na estrada. Registre e visualize facilmente quais caminhões estão associados a quais reboques, e fique por dentro de todas as alterações. Garanta operações seguras, otimizadas e em conformidade com cada movimento.'
            },
            {
              name: 'Controle de Quilometragem',
              active: true,
              helperText:
                'Acompanhe cada quilômetro percorrido com precisão. Nossa ferramenta ajuda você a monitorar a distância percorrida por cada veículo, otimizando manutenções, gerenciando custos e garantindo rotas eficientes.'
            }
          ]
        }
      ]
    }
  ];
};

export type PlanFunctionality = {
  name: string;
  active: boolean;
  helperText?: string;
  description?: string | number;
};

type PlanModule = {
  name: string;
  functionalities: PlanFunctionality[];
};

export type PlanDetails = {
  name: string;
  helperText?: string;
  color: string;
  lineColor: string;
  paymentLink: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonAction: any;
  functionalities: PlanFunctionality[];
  module?: PlanModule[];
  currentPlan?: boolean;
  disableColumn?: boolean;
};
