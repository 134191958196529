import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  // firstName: z.string().min(2).max(50).optional(),
  // lastName: z.string().min(2).max(50).optional(),
  fullName: z
    .string()
    .min(2)
    .max(100)
    // .regex(/^[a-zA-Z\u00C0-\u00FF]{2,}(?: [a-zA-Z\u00C0-\u00FF]+){0,4}$/)
    .regex(
      /^[a-zA-Z\u00C0-\u00FF'!"#$%&()*+,\-.\/:;<=>?@[\\\]^_`{|}~]+(?: [a-zA-Z\u00C0-\u00FF'!"#$%&()*+,\-.\/:;<=>?@[\\\]^_`{|}~]+)+\s*$/
    ),

  email: z.string().email(),
  carQuantity: z.string()
  // password: z
  //   .string()
  //   .min(8)
  //   .regex(
  //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-])/,
  //     'invalidPassword'
  //   )
  //   .optional()
});

export const PersonalDataValidator = zodResolver(schema);
