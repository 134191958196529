import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import CardNumberTextField from './card-number-text-field/card-number-text-field';
import {
  CreditCardFormContainer,
  CreditCardTitle
} from './credit-card-form-styles';
import CVVTextField from './cvv-text-field/cvv-text-field';
import ExpirationDateTextField from './expiration-date-text-field/expiration-date-text-field';
import HolderNameTextField from './holder-name-text-field/holder-name-text-field';

type CreditCardFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: any;
};

const CreditCardForm = ({ control, setValue }: CreditCardFormProps) => {
  const { t } = useTranslation('components');

  return (
    <CreditCardFormContainer>
      <Grid container spacing={{ md: 4, xs: 2 }}>
        <Grid item xs={12}>
          <CreditCardTitle>{t('title')}</CreditCardTitle>
        </Grid>

        <Grid item xs={12}>
          <CardNumberTextField setValue={setValue} control={control} />
        </Grid>

        <Grid item xs={12} md={6}>
          <HolderNameTextField control={control} />
        </Grid>

        <Grid item xs={6} md={3}>
          <ExpirationDateTextField control={control} />
        </Grid>

        <Grid item xs={6} md={3}>
          <CVVTextField control={control} />
        </Grid>
      </Grid>
    </CreditCardFormContainer>
  );
};
export default CreditCardForm;
