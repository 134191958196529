import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  identifier: z
    .string()
    .refine((value) => /^\S+@\S+\.\S+$/.test(value) || /^\w+$/.test(value)),
  password: z.string().min(8)
});

export const LoginFormValidation = zodResolver(schema);
