import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Skeleton } from '@mui/material';
import PlanCard from '~/app/application/features/components/plan-card/plan-card';
import PlanDetailsTable from '~/app/application/features/components/plan-details-table/plan-details-table';
import SuccessSetting from '~/app/application/features/components/success-setting/success-setting';
import { logEvent } from '~/app/application/shared/lib/tag';

import { APP_ENV } from '../../../../../../../../env';
import { Price } from '../../../../domain/models/plans/plan-response-model';
import { SubscriptionModel } from '../../../../domain/models/subscriptions/create-subscription-model';
import { RegisterRoutes } from '../../../../routes/routes';
import {
  useAddVehiclesMutation,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation
} from '../../../../store/hooks';
import { useRegisterUserDataStore } from '../../../../store/slice/register-user-data-slice';
import ModalAlertPlan from './modal-alert-plan/modal-alert-plan';
import { createPlans } from './plans-list';
import {
  PlansDialog,
  PlansCardsContainer,
  SelectLaterButton,
  SelectLaterContainer,
  SecondTitle
} from './select-plan-step-styles';

const SelectPlanStep = () => {
  const { i18n } = useTranslation();
  const planId = APP_ENV.FREE_PLAN_ID;
  const normalizedLanguage = i18n.language.replace('-', '_');

  const navigate = useNavigate();

  const [createSubscription] = useCreateSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();

  const [currency, setCurrency] = useState('R$');
  const [yearlyPrice, setYearlyPrice] = useState<Price>();
  const [monthlyPrice, setMonthlyPrice] = useState<Price>();
  const [addVehicles] = useAddVehiclesMutation();
  const [startedIsLoading, setStartedIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [displaySuccess, setDisplaySuccess] = useState(false);

  const { registerState } = useRegisterUserDataStore();

  const plans = registerState.plans;

  const isLoading = !registerState.plans;

  const addVehiclesRequest = (isStarter?: boolean) => {
    const modifiedFormData = isStarter
      ? {
          ...registerState.vehicles,
          vehicleList: registerState.vehicles.vehicleList.slice(0, 10)
        }
      : registerState.vehicles;

    addVehicles(modifiedFormData)
      .unwrap()
      .catch((error) => {
        console.error(error);
      });
  };

  const onDisplayPaymentPlanPro = () => {
    logEvent({
      category: 'step06_select_plan_pro/trigger',
      action: 'OnSubmit',
      label: 'step06_select_plan_pro'
    });

    return navigate(RegisterRoutes.PLAN_PRO);
  };

  const onDisplayContactSales = () => {
    logEvent({
      category: 'step06_select_plan_enterprise/trigger',
      action: 'OnSubmit',
      label: 'step06_select_plan_enterprise'
    });

    return navigate(RegisterRoutes.PLAN_ENTERPRISE);
  };

  const onDisplaySelectPlanAfter = () => {
    logEvent({
      category: 'step06_select_plan_after/trigger',
      action: 'OnSubmit',
      label: 'step06_select_plan_after'
    });
    return onValidateFreePlan();
  };

  const onSelectPlanStarter = () => {
    logEvent({
      category: 'step06_select_plan_starter/trigger',
      action: 'OnSubmit',
      label: 'step06_select_plan_starter'
    });

    setStartedIsLoading(false);
  };

  const onValidateFreePlan = () => {
    onSelectPlanStarter();
    if (registerState.vehiclesCount <= 10) return toogleSuccess();

    return setModalOpen(true);
  };

  const createSubscriptionRequest = (values: SubscriptionModel) => {
    createSubscription({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then(() => {
        setDisplaySuccess(!displaySuccess);
      })
      .catch(() => {
        setStartedIsLoading(false);
        console.error('error in create subscription');
      });
  };

  const updateSubscriptionRequest = (values: SubscriptionModel) => {
    updateSubscription({ ...values, isSelfOnboarding: true })
      .unwrap()
      .then(() => {
        setDisplaySuccess(!displaySuccess);
      })
      .catch(() => {
        setStartedIsLoading(false);
        console.error('error in create subscription');
      });
  };

  const toogleSuccess = () => {
    setStartedIsLoading(true);
    const freePlan = plans.find((plan) => plan.type === 'free');
    if (!freePlan) console.error('free plan not found');

    registerState.vehicles && addVehiclesRequest(true);

    if (registerState.companies[0].subscriptions.length > 0) {
      return updateSubscriptionRequest({
        subscriptionId: registerState.companies[0].subscriptions[0],
        companyId: registerState.companies[0]?._id,
        planId: freePlan?._id ?? planId
      });
    }

    return createSubscriptionRequest({
      companyId: registerState.companies[0]?._id,
      planId: freePlan?._id ?? planId
    });
  };

  const plansForTable = createPlans({
    freePlan: onValidateFreePlan,
    proPlan: onDisplayPaymentPlanPro,
    enterprisePlan: onDisplayContactSales
  });

  useEffect(() => {
    setCurrency(plans[0]?.products[0]?.prices[0]?.symbol);
    const yearlyPrice = plans[0]?.products[0]?.prices.find(
      (price) => price.frequency === 'yearly'
    );
    setYearlyPrice(yearlyPrice);
    const monthlyPrice = plans[0]?.products[0]?.prices.find(
      (price) => price.frequency === 'monthly'
    );
    setMonthlyPrice(monthlyPrice);
  }, [plans]);

  const handleClose = () => setModalOpen(false);

  return (
    <div>
      <ModalAlertPlan
        open={modalOpen}
        onClose={handleClose}
        onUseStarter={toogleSuccess}
        onChooseProEnterprise={handleClose}
      />
      <h1>Escolha o plano ideal para você e sua frota</h1>
      <PlansCardsContainer>
        {isLoading ? (
          <>
            <Skeleton variant='rectangular' width={340} height={359} />
            <Skeleton variant='rectangular' width={340} height={359} />
            <Skeleton variant='rectangular' width={340} height={359} />
          </>
        ) : (
          <>
            <PlanCard
              color='#63AB36'
              title='Starter'
              currency='R$'
              price={0}
              isLoading={startedIsLoading}
              description='Indicado para pequenas empresas ou indivíduos que querem experimentar nosso serviço sem custo inicial.'
              buttonText='Experimente Grátis'
              buttonAction={onValidateFreePlan}
            />
            <PlanCard
              badgeText='Mais comprado'
              color='#457826'
              title={plans[0]?.name?.[normalizedLanguage]}
              currency={currency}
              price={yearlyPrice?.amount}
              description={plans[0]?.description?.[normalizedLanguage]}
              buttonText='Começar com Pro'
              observation={`* O valor é cobrado anualmente. ${monthlyPrice?.symbol} ${monthlyPrice?.amount
                .toFixed(2)
                .replace('.', ',')} quando faturado mensalmente.`}
              buttonAction={onDisplayPaymentPlanPro}
            />
            <PlanCard
              color='#284516'
              title='Enterprise'
              currency='Preço Customizado'
              description='Indicado para grandes empresas que têm necessidades específicas e que desejam o melhor custo-benefício em serviços adicionais.'
              buttonText='Fale Com Vendas'
              buttonAction={onDisplayContactSales}
            />
          </>
        )}
      </PlansCardsContainer>
      {!isLoading && <SecondTitle>Compare os Benefícios</SecondTitle>}

      <PlanDetailsTable plansData={plansForTable} isLoading={isLoading} />

      {!isLoading && (
        <SelectLaterContainer>
          <SelectLaterButton
            onClick={() => onDisplaySelectPlanAfter()}
            variant='text'
            color='primary'
          >
            Selecionar Depois
          </SelectLaterButton>
        </SelectLaterContainer>
      )}
      <PlansDialog
        fullScreen
        hideBackdrop
        disableEscapeKeyDown
        open={displaySuccess}
        onClose={toogleSuccess}
      >
        <SuccessSetting
          status='loading'
          title='Cadastro Concluído com Sucesso!'
          description='Estamos dando os toques finais na sua área de trabalho, que estará pronta muito em breve.'
          redirectLink={APP_ENV.FRONTEND_V1_REDIRECT_URL}
          redirectToAfterTimeout={3}
        />
      </PlansDialog>
    </div>
  );
};
export default SelectPlanStep;
