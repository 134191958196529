import { apiSlice } from '../../../../core/store/api-slice';
import {
  AccessCode,
  accessCodeService,
  validadeAccessCodeService,
  ValidateAccessCode
} from '../services/access-code-login';
import { AddVehicles, addVehiclesService } from '../services/add-vehicles';
import { CNPJLookup, cnpjLookupService } from '../services/cnpj-lookup';
import { CNPJValidate, cnpjValidateService } from '../services/cnpj-validate';
import { Consumables, consumablesService } from '../services/consumables';
import { ContactSales, contactSalesService } from '../services/contact-sales';
import {
  CreateCompany,
  createCompanyService
} from '../services/create-company';
import {
  CreateSubscription,
  createSubscriptionService
} from '../services/create-subscription';
import {
  EmailOrUsernameLogin,
  emailOrUsernameLoginService
} from '../services/email-login';
import {
  GetSubscriptionByCompanyId,
  getSubscriptionByCompanyIdService
} from '../services/get-subscription-by-company-id';
import { getSubscriptionService } from '../services/get-subscription-by-id';
import { GetSubscription } from '../services/get-subscription-by-id/get-subscription-by-id';
import { getVehiclesService } from '../services/get-vehicles';
import { GetVehicles } from '../services/get-vehicles/get-vehicles';
import {
  AuthorizationUrl,
  authorizationUrlLoginService,
  googlelLoginService,
  GoogleLogin
} from '../services/google-login';
import { LoginByLink, loginByLinkService } from '../services/login-by-link';
import { Me, meService } from '../services/me';
import {
  ChangePassword,
  changePasswordService,
  passwordService
} from '../services/password';
import { Password } from '../services/password/password';
import { GetPlans, getPlansService } from '../services/plans/get-plans';
import { ResendCode, resendCodeService } from '../services/resend-code';
import {
  SendEmailLinkFlow,
  sendEmailLinkFlowService
} from '../services/send-email-link-flow';
import { signUpService } from '../services/sign-up';
import { SignUp } from '../services/sign-up/sign-up';
import { UpdateMe, updateMeService } from '../services/update-me';
import {
  UpdateSubscription,
  updateSubscriptionService
} from '../services/update-subscription';
import { ValidateCode, validateCodeService } from '../services/validate-code';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    emailOrUsernameLogin: builder.mutation<
      EmailOrUsernameLogin.Response,
      EmailOrUsernameLogin.Params
    >({
      query: (params) => ({
        service: emailOrUsernameLoginService,
        params
      })
    }),
    password: builder.mutation<Password.Response, Password.Params>({
      query: (params) => ({
        service: passwordService,
        params
      })
    }),
    changePassword: builder.mutation<
      ChangePassword.Response,
      ChangePassword.Params
    >({
      query: (params) => ({
        service: changePasswordService,
        params
      })
    }),
    consumables: builder.mutation<Consumables.Response, Consumables.Params>({
      query: (params) => ({
        service: consumablesService,
        params
      })
    }),
    googleLogin: builder.mutation<GoogleLogin.Response, GoogleLogin.Params>({
      query: (params) => ({
        service: googlelLoginService,
        params
      })
    }),
    loginByLink: builder.mutation<LoginByLink.Response, LoginByLink.Params>({
      query: (params) => ({
        service: loginByLinkService,
        params
      })
    }),
    authorizationUrl: builder.mutation<AuthorizationUrl.Response, ''>({
      query: () => ({
        service: authorizationUrlLoginService
      })
    }),
    accessCode: builder.mutation<AccessCode.Response, AccessCode.Params>({
      query: (params) => ({
        service: accessCodeService,
        params
      })
    }),
    validateAccessCode: builder.mutation<
      ValidateAccessCode.Response,
      ValidateAccessCode.Params
    >({
      query: (params) => ({
        service: validadeAccessCodeService,
        params
      })
    }),
    signUp: builder.mutation<SignUp.Response, SignUp.Params>({
      query: (params) => ({
        service: signUpService,
        params
      })
    }),
    validateCode: builder.mutation<ValidateCode.Response, ValidateCode.Params>({
      query: (params) => ({
        service: validateCodeService,
        params
      })
    }),
    sendEmailLinkFlow: builder.mutation<
      SendEmailLinkFlow.Response,
      SendEmailLinkFlow.Params
    >({
      query: (params) => ({
        service: sendEmailLinkFlowService,
        params
      })
    }),
    resendCode: builder.mutation<ResendCode.Response, ResendCode.Params>({
      query: (params) => ({
        service: resendCodeService,
        params
      })
    }),
    cnpjLookup: builder.mutation<CNPJLookup.Response, CNPJLookup.Params>({
      query: (params) => ({
        service: cnpjLookupService,
        params
      })
    }),
    cnpjValidate: builder.mutation<CNPJValidate.Response, CNPJValidate.Params>({
      query: (params) => ({
        service: cnpjValidateService,
        params
      })
    }),
    createCompany: builder.mutation<
      CreateCompany.Response,
      CreateCompany.Params
    >({
      query: (params) => ({
        service: createCompanyService,
        params
      })
    }),
    addVehicles: builder.mutation<AddVehicles.Response, AddVehicles.Params>({
      query: (params) => ({
        service: addVehiclesService,
        params
      })
    }),
    contactSales: builder.mutation<ContactSales.Response, ContactSales.Params>({
      query: (params) => ({
        service: contactSalesService,
        params
      })
    }),
    getPlans: builder.mutation<GetPlans.Response, GetPlans.Params>({
      query: (params) => ({
        service: getPlansService,
        params
      })
    }),
    getSubscriptionById: builder.mutation<
      GetSubscription.Response,
      GetSubscription.Params
    >({
      query: (params) => ({
        service: getSubscriptionService,
        params
      })
    }),
    getSubscriptionByCompanyId: builder.mutation<
      GetSubscriptionByCompanyId.Response,
      GetSubscriptionByCompanyId.Params
    >({
      query: (params) => ({
        service: getSubscriptionByCompanyIdService,
        params
      })
    }),
    createSubscription: builder.mutation<
      CreateSubscription.Response,
      CreateSubscription.Params
    >({
      query: (params) => ({
        service: createSubscriptionService,
        params
      })
    }),
    updateSubscription: builder.mutation<
      UpdateSubscription.Response,
      UpdateSubscription.Params
    >({
      query: (params) => ({
        service: updateSubscriptionService,
        params
      })
    }),
    updateMe: builder.mutation<UpdateMe.Response, UpdateMe.Params>({
      query: (params) => ({
        service: updateMeService,
        params
      })
    }),
    me: builder.mutation<Me.Response, object>({
      query: (params) => ({
        service: meService,
        params
      })
    }),
    getMe: builder.query<Me.Response, object>({
      query: (params) => ({
        service: meService,
        params
      })
    }),
    getVehicles: builder.mutation<GetVehicles.Response, GetVehicles.Params>({
      query: (params) => ({
        service: getVehiclesService,
        params
      })
    })
  })
});
