import { httpClient } from '~/app/core/infra/http/axios-http-client-adapter';

import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { AUTH_API_ROUTES } from '../../api/routes';
import { CreateSubscription } from './create-subscription';

export const createSubscriptionService: ServiceCommand<CreateSubscription.Response> =
  new CreateSubscription(httpClient, AUTH_API_ROUTES.CREATE_SUBSCRIPTION);

export type { CreateSubscription };
