import { httpClient } from '~/app/core/infra/http/axios-http-client-adapter';

import { ServiceCommand } from '../../../../../core/domain/command/service-command';
import { AUTH_API_ROUTES } from '../../api/routes';
import { UpdateSubscription } from './update-subscription';

export const updateSubscriptionService: ServiceCommand<UpdateSubscription.Response> =
  new UpdateSubscription(httpClient, AUTH_API_ROUTES.UPDATE_SUBSCRIPTION);

export type { UpdateSubscription };
