export const carQuantity = [
  {
    value: '5 a 10',
    label: '5 a 10'
  },
  {
    value: '11 a 100',
    label: '11 a 100'
  },
  {
    value: 'Acima de 100',
    label: 'Acima de 100'
  }
];
