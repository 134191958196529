import React, { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { TextFieldProps } from '@mui/material';
import BaseTextField from '~/app/core/presentation/components/base-text-field/base-text-field';

export type QuantityCarTextFieldProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  errorText?: string;
  isLoading?: boolean;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberMask = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, _) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask='0000000000000000'
        min={0}
        max={999999999}
        radix='.'
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        {...other}
      />
    );
  }
);

const QuantityCarTextField = (props: QuantityCarTextFieldProps) => {
  const { t } = useTranslation(['components', 'errors']);
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  return (
    <Controller
      name='carQuantity'
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseTextField
          onChange={onChange}
          value={value || ''}
          error={!!error}
          helperText={
            error ? props.errorText ?? t('errors:carQuantity') : undefined
          }
          id='carQuantity'
          name='carQuantity'
          label={t('carQuantity')}
          variant='filled'
          type='text'
          required
          inputProps={{ maxLength: 9 }} // Set max length to 9
          InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: NumberMask as any
          }}
          inputRef={inputElementRef}
          {...props}
        />
      )}
    />
  );
};

export default QuantityCarTextField;
